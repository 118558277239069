import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: "root",
})
export class HostDetectionService {
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private injector: Injector,
    private deviceService: DeviceDetectorService
  ) {}

  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  public get isIOS(): boolean {
    return this.deviceService.os.toLowerCase().includes("ios");
  }

  public get isAndroid(): boolean {
    return this.deviceService.os.toLowerCase().includes("android");
  }

  public get hostname(): string {
    if (this.isServer) {
      return this.injector.get("request").hostname;
    }

    return window?.location?.hostname;
  }
}
