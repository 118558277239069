import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { dynamicEnvironment } from "environments/dynamic-environment";

export interface AvatarFile {
  contentType: string;
  createdAt: string;
  id: number;
  name: string;
  size: number;
  url: string;
}

export interface User {
  agreementAcceptedAt: null | boolean;
  avatarFile: AvatarFile;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  middleName: string;
  phone: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public user$: BehaviorSubject<User> = new BehaviorSubject(null);
  private token: string | undefined;
  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private http: HttpClient
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      const token = localStorage.getItem("appAuthToken");

      if (token) {
        this.token = token;
        this.fetchUser();
      }
    }
  }

  fetchUser() {
    this.http
      .get(`${dynamicEnvironment.environment.appApiBaseUrl}/v1/auth/me/`, {
        headers: { authorization: `JWT ${this.token}` },
      })
      .subscribe((user: User) => this.user$.next(user));
  }
}
