import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { ArrowBtnComponent } from "./arrow-btn.component";

@NgModule({
  declarations: [ArrowBtnComponent],
  imports: [CommonModule, RouterModule],
  exports: [ArrowBtnComponent],
})
export class ArrowBtnModule {}
