import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserProfileMenuComponent } from "./components/user-profile-menu/user-profile-menu.component";

@NgModule({
  declarations: [UserProfileMenuComponent],
  imports: [CommonModule],
  exports: [UserProfileMenuComponent],
})
export class UserProfileModule {}
