import { Component, OnInit } from "@angular/core";
import { CookieMessageService } from "app/services/cookie-message.service";

@Component({
  selector: "app-warning-cookies",
  templateUrl: "./warning-cookies.component.html",
  styleUrls: ["./warning-cookies.component.scss"],
})
export class WarningCookiesComponent implements OnInit {
  public isWarning: boolean = false;

  constructor(private cookieMessageService: CookieMessageService) {}

  ngOnInit(): void {
    this.cookieMessageService.$isOpen.subscribe(
      (isOpen: boolean) => (this.isWarning = isOpen)
    );
  }

  handleWarning() {
    this.isWarning = false;
    this.cookieMessageService.close();
  }
}
