import { Component, OnInit } from "@angular/core";
import { CookieMessageService } from "app/services/cookie-message.service";
import { HostDetectionService } from "../../../../services/host-detection.service";
import { DomainsEnum } from "../../../../../constants/data-source";
import { BannerShowService } from "app/services/banner-show.service";
import { AnalyticsService } from "app/services/analytics.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  analyticsQueryParams = "";
  constructor(
    private cookieMessageService: CookieMessageService,
    private bannerShowService: BannerShowService,
    private hostDetectionService: HostDetectionService,
    private analyticsService: AnalyticsService
  ) {}

  public withCookieSpacer: boolean = false;
  public withBannerSpacer: boolean = false;
  public isBy: boolean = false;
  public year: number;

  ngOnInit() {
    this.cookieMessageService.$isOpen.subscribe(
      (isOpen: boolean) => (this.withCookieSpacer = isOpen)
    );
    this.bannerShowService.$isOpen.subscribe(
      (isOpen: boolean) => (this.withBannerSpacer = isOpen)
    );
    this.isBy = this.hostDetectionService.hostname === DomainsEnum.UPSERVICE_BY;
    this.year = new Date().getFullYear();

    this.analyticsService.params$.subscribe(
      (params) => (this.analyticsQueryParams = params)
    );
  }
}
