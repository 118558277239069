<header class="header" [class.with-banner-spacer]="withBannerSpacer">
  <div [class.banner-spacer]="withBannerSpacer"></div>

  <div class="header-inner">
    <div class="header-nav">
      <div class="wrap-header-nav container">
        <div class="wrap-logo">
          <a class="logo" [routerLink]="['/']">
            <img
              loading="lazy"
              [src]="logo"
              alt="Upservice"
              width="131"
              height="30"
            />
          </a>
        </div>
        <nav class="nav">
          <ul class="nav-list">
            <li class="nav-item">
              <span>Бизнес решения</span>
              <img loading="lazy" class="image" src="assets/arrow-button.svg" />

              <div class="submenu submenu-large">
                <ul class="submenu-column">
                  <li class="submenu-item item-title">Для обслуживания</li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/it-autsorsing']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      IT-аутсорсинга
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      routerLink="/upravlenie-kommercheskoj-nedvizhimostyu"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Коммерческой недвижимости
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/obsluzhivanie-oborudovaniya-tekhniki']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Техники и оборудования
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/obslujivanie-restoranov-i-gostinic']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Ресторанов и гостиниц
                    </a>
                  </li>

                  <!-- <li class="submenu-item">
                  <a
                    [routerLink]="['/']"
                    class="submenu-nav-link"
                    routerLinkActive="link-active"
                  >
                    Розничных сетей
                  </a>
                </li> -->

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/tekhnicheskaya-podderzhka']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Техподдержки
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/kliningovye-uslugi']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Клининговые услуги
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="[
                        '/arenda-oborudovaniya-tekhniki-nedvizhimosti'
                      ]"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Арендный бизнес
                    </a>
                  </li>

                  <!-- <li class="submenu-item">
                  <a
                    [routerLink]="['/']"
                    class="brand btn-with-arrow"
                  >
                    Другое
                    <span>
                      <svg
                        width="30"
                        height="12"
                        viewBox="0 0 30 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.5303 6.53033C25.8232 6.23743 25.8232 5.76256 25.5303 5.46967L20.7574 0.696697C20.4645 0.403804 19.9896 0.403804 19.6967 0.696697C19.4038 0.989591 19.4038 1.46446 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM6.55671e-08 6.75L25 6.75L25 5.25L-6.55671e-08 5.25L6.55671e-08 6.75Z"
                          fill="#36B7BD"
                        />
                      </svg>
                    </span>
                  </a>
                </li> -->
                </ul>

                <ul class="submenu-column">
                  <li class="submenu-item item-title">Возможности</li>

                  <!-- <li class="submenu-item">
                  <a
                    [routerLink]="['/']"
                    class="submenu-nav-link"
                    routerLinkActive="link-active"
                  >
                    Учет заявок клиентов
                  </a>
                </li> -->

                  <!-- <li class="submenu-item">
                  <a
                    [routerLink]="['/']"
                    class="submenu-nav-link"
                    routerLinkActive="link-active"
                  >
                    Внутренняя техподдержка
                  </a>
                </li> -->

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/vyezdnoe-obsluzhivanie']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Выездное обслуживание
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/soglasovanie-dokumentov']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Согласование документов
                    </a>
                  </li>

                  <!-- <li class="submenu-item">
                  <a
                    [routerLink]="['/']"
                    class="submenu-nav-link"
                    routerLinkActive="link-active"
                  >
                    Абонентское обслуживание
                  </a>
                </li> -->

                  <!-- <li class="submenu-item">
                  <a
                    [routerLink]="['/']"
                    class="submenu-nav-link"
                    routerLinkActive="link-active"
                  >
                    Управление закупками
                  </a>
                </li> -->

                  <!-- <li class="submenu-item">
                  <a [routerLink]="['/']" class="btn-with-arrow">
                    Другое
                    <span>
                      <svg
                        width="30"
                        height="12"
                        viewBox="0 0 30 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.5303 6.53033C25.8232 6.23743 25.8232 5.76256 25.5303 5.46967L20.7574 0.696697C20.4645 0.403804 19.9896 0.403804 19.6967 0.696697C19.4038 0.989591 19.4038 1.46446 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM6.55671e-08 6.75L25 6.75L25 5.25L-6.55671e-08 5.25L6.55671e-08 6.75Z"
                          fill="#F9807D"
                        />
                      </svg>
                    </span>
                  </a>
                </li> -->
                </ul>
              </div>
            </li>

            <li class="nav-item">
              <span>Инструменты</span>
              <img loading="lazy" class="image" src="assets/arrow-button.svg" />

              <div class="submenu">
                <ul class="submenu-column">
                  <li class="submenu-item">
                    <a
                      [routerLink]="['/service-desk']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Service Desk
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/help-desk']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Help Desk
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/task-menedzher']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Менеджер задач
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [href]="'https://promo.upservice.com/project-management' + analyticsQueryParams"
                      class="submenu-nav-link"
                    >
                      Управление проектами
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/crm-dlya-b2b']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      CRM для B2B
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [href]="'https://promo.upservice.com/omnichannel-service' + analyticsQueryParams"
                      class="submenu-nav-link"
                    >
                      Омниканальный сервис
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [routerLink]="['/chat']"
                      class="submenu-nav-link"
                      routerLinkActive="link-active"
                    >
                      Чат для сайта
                    </a>
                  </li>

                  <li class="submenu-item">
                    <a
                      [href]="'https://promo.upservice.com/box' + analyticsQueryParams"
                      class="submenu-nav-link"
                    >
                      Коробочное решение
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                [href]="'https://promo.upservice.com/pricing' + analyticsQueryParams"
                routerLinkActive="link-active"
              >
                Цены
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" [href]="'https://promo.upservice.com/contact' + analyticsQueryParams">
                Контакты
              </a>
            </li>

            <li class="nav-item nav-item-sign-in" *ngIf="!user">
              <a
                class="btn-transparent"
                [class.brand]="
                  currentRoute === '/' ||
                  '/arenda-oborudovaniya-tekhniki-nedvizhimosti'
                "
                [href]="
                  appUrlService.createUrl('/auth/login') + analyticsQueryParams
                "
              >
                Войти
              </a>
            </li>
          </ul>

          <div *ngIf="!user; else userProfile">
            <a
              class="btn-fill-red-medium btn-sign-up"
              [class.brand]="
                currentRoute === '/' ||
                '/arenda-oborudovaniya-tekhniki-nedvizhimosti'
              "
              [href]="'https://promo.upservice.com/registration-step-1' + analyticsQueryParams">
              Зарегистрироваться
            </a>
          </div>

          <div class="nav-mobile">
            <button
              type="button"
              class="btn-burger"
              (click)="onChangeVisibleSidebar(true)"
            >
              <img
                src="assets/burger.svg"
                alt="burger"
                width="32"
                height="32"
              />
            </button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>

<ng-template #userProfile>
  <app-user-profile-menu
    class="user-profile"
    [user]="user"
  ></app-user-profile-menu>
</ng-template>

<app-sidebar
  [visible]="mobileMenuVisible"
  (onClose)="onChangeVisibleSidebar($event)"
>
  <div class="app-mobile-menu">
    <div *ngFor="let item of items; let index = index">
      <div
        class="menu-item {{ item.styleClass ? item.styleClass : null }}"
        (click)="item.isNotLink ? toggleSubmenu(index) : null"
      >
        <a
          *ngIf="!item.isNotLink"
          [href]="
            item.withoutQuery
              ? item.routerLink
              : item.routerLink + analyticsQueryParams
          "
          (click)="!item.routerLink ? item.command() : null"
        >
          {{ item.label }}
        </a>

        <span *ngIf="item.isNotLink">
          {{ item.label }}
        </span>

        <span *ngIf="item.items" class="toggle-icon">
          <img loading="lazy" class="image" src="assets/arrow-button.svg" />
        </span>
      </div>
      <div class="submenu">
        <div
          *ngFor="let subItem of item.items"
          class="menu-item {{ subItem.styleClass }}"
        >
          <a
            *ngIf="!subItem.isNotLink"
            [href]="subItem.routerLink + analyticsQueryParams"
            (click)="!subItem.routerLink ? subItem.command() : null"
            class="{{ subItem.withArrow ? 'btn-with-arrow' : null }}"
          >
            {{ subItem.label }}

            <span *ngIf="subItem.withArrow">
              <svg
                width="30"
                height="12"
                viewBox="0 0 30 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.5303 6.53033C25.8232 6.23743 25.8232 5.76256 25.5303 5.46967L20.7574 0.696697C20.4645 0.403804 19.9896 0.403804 19.6967 0.696697C19.4038 0.989591 19.4038 1.46446 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM6.55671e-08 6.75L25 6.75L25 5.25L-6.55671e-08 5.25L6.55671e-08 6.75Z"
                  fill="#F9807D"
                />
              </svg>
            </span>
          </a>

          <span *ngIf="subItem.isNotLink">
            {{ subItem.label }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <app-user-profile-menu
    *ngIf="user"
    class="user-profile-mobile"
    [user]="user"
  ></app-user-profile-menu>
</app-sidebar>
