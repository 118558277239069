<div class="banner" *ngIf="isBannerShown">
  <a
    class="banner__wrapper"
    [href]="
      'https://promo.upservice.com/registration-step-1' + analyticsQueryParams
    "
    target="_blank"
  >
    <div class="container">
      <div class="banner__content">
        <div class="banner__icon hidden-on-mobile">
          <img
            src="https://landing.upservice.io/wp-content/uploads/horeca-maintanance/banner-icon.svg"
            alt="banner-icon"
          />
        </div>

        <div class="banner__text">
          Ура!
          <span class="hidden-on-mobile">
            Мы объявляем
            <strong class="strong">ФИНАНСОВЫЕ КАНИКУЛЫ.</strong>

            <br />
          </span>
          Весь функционал
          <span class="hidden-on-mobile">
            для новых клиентов предоставляем
          </span>
          бесплатно <span class="hidden-on-mobile"> и без ограничений </span> до
          <br class="show-on-mobile" />
          <strong>
            {{ date | date : "dd.MM.YYYY" }}
            <span class="show-on-mobile">. </span>
          </strong>

          <span
            class="text-note hidden-on-mobile"
            [innerHTML]="
              '&nbsp(в связи со сложной экономической ситуацией в
              стране).&nbsp'
            "
          ></span>

          <app-arrow-btn
            [href]="
              'https://promo.upservice.com/registration-step-1' +
              analyticsQueryParams
            "
            text="Попробовать"
            target="_blank"
            color="white"
            class="financial-holidays"
          ></app-arrow-btn>
        </div>
      </div>
    </div>
  </a>

  <div class="close" (click)="bannerCloseHandler()"></div>
</div>
