<footer class="footer">
  <div class="container">
    <div class="wrap-footer">
      <div class="wrap-social">
        <div class="wrap-logo">
          <a [routerLink]="['/']">
            <img
              loading="lazy"
              src="assets/footer/logo.svg"
              alt="Upservice"
              width="100"
              height="21"
            />
          </a>
        </div>

        <div class="social-list">
          <a
            href="https://www.instagram.com/upservicecom/"
            class="social-item"
            target="_blank"
          >
            <img
              loading="lazy"
              class="social-img"
              src="assets/footer/instagram.svg"
              alt="Instagram"
              width="40"
              height="40"
            />
          </a>

          <a
            href="https://www.facebook.com/upservicecom/"
            class="social-item"
            target="_blank"
          >
            <img
              loading="lazy"
              class="social-img"
              src="assets/footer/facebook.svg"
              alt="Facebook"
              width="40"
              height="40"
            />
          </a>

          <a
            href="https://vk.com/upservicecom"
            class="social-item"
            target="_blank"
          >
            <img
              loading="lazy"
              class="social-img"
              src="assets/footer/vk.svg"
              alt="vk"
              width="40"
              height="40"
            />
          </a>

          <a
            href="https://www.youtube.com/@upservicecom"
            class="social-item"
            target="_blank"
          >
            <img
              loading="lazy"
              class="social-img"
              src="assets/footer/youtube.svg"
              alt="Youtube"
              width="40"
              height="40"
            />
          </a>
          <a
            href="https://t.me/upservice_support_bot"
            class="social-item"
            target="_blank"
          >
            <img
              loading="lazy"
              class="social-img"
              src="assets/footer/telegram-white.svg"
              alt="Telegram"
              width="40"
              height="40"
            />
          </a>
        </div>

        <div class="phone-markets-list">
          <a
            href="https://play.google.com/store/apps/details?id=com.upservice.android"
            class="phone-market-item"
            target="_blank"
          >
            <img
              loading="lazy"
              class="phone-market-img"
              src="assets/footer/google-play.svg"
              alt="Google Play"
              width="182"
              height="48"
            />
          </a>

          <a
            href="https://apps.apple.com/ru/app/upservice/id1510273341"
            class="phone-market-item"
            target="_blank"
          >
            <img
              loading="lazy"
              class="phone-market-img"
              src="assets/footer/app-store.svg"
              alt="App Store"
              width="182"
              height="48"
            />
          </a>
        </div>

        <p class="copyright">©{{ year }} Upservice / Апсервис</p>
      </div>

      <div class="wrap-nav">
        <ul class="nav-list">
          <li class="nav-item">
            <a [routerLink]="['/service-desk']" class="nav-link"
              >Service Desk</a
            >
          </li>

          <li class="nav-item">
            <a [routerLink]="['/help-desk']" class="nav-link">Help Desk</a>
          </li>

          <li class="nav-item">
            <a [routerLink]="['/task-menedzher']" class="nav-link">
              Менеджер задач
            </a>
          </li>

          <li class="nav-item">
            <a [routerLink]="['/crm-dlya-b2b']" class="nav-link">CRM для B2B</a>
          </li>

          <li class="nav-item">
            <a [routerLink]="['/chat']" class="nav-link">Чат для сайта</a>
          </li>
        </ul>

        <ul class="nav-list">
          <li class="nav-item">
            <a
              [href]="'https://promo.upservice.com/pricing' + analyticsQueryParams"
              target="_blank"
              class="nav-link">
              Цены
            </a>
          </li>

          <li class="nav-item">
            <a [routerLink]="['/how-it-works']" class="nav-link"
              >Как это работает?</a
            >
          </li>

          <!-- <li *ngIf="isBy" class="nav-item">
            <a [routerLink]="['/partners']" class="nav-link">Партнерам</a>
          </li> -->

          <li class="nav-item">
            <a [routerLink]="['/white-label']" class="nav-link">White label</a>
          </li>

          <li class="nav-item">
            <a href="https://help.upservice.com/" target="_blank" class="nav-link"
              >База знаний</a
            >
          </li>

          <li class="nav-item">
            <a href="https://case.upservice.com/" target="_blank" class="nav-link"
            >Примеры внедрения</a
            >
          </li>
        </ul>

        <ul class="nav-list">
          <li class="nav-item">
            <a href="/public-agreement.pdf" target="_blank" class="nav-link">
              Пользовательское соглашение
            </a>
          </li>

          <li class="nav-item">
            <a href="/privacy-policy" target="_blank" class="nav-link">
              Конфиденциальность
            </a>
          </li>

          <li class="nav-item">
            <a [href]="'https://promo.upservice.com/contact' + analyticsQueryParams" class="nav-link">
              Контакты
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div [class.withCookieSpacer]="withCookieSpacer"></div>
</footer>
