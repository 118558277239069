import { Injectable } from "@angular/core";
import { HostDetectionService } from "./host-detection.service";
import { BehaviorSubject } from "rxjs";
import { DomainsEnum } from "../../constants/data-source";
import { NavigationEnd, Router } from "@angular/router";

const PARAMS_BY_HOSTNAME = {
  [DomainsEnum.UPSERVICE_BY]: "?utm_source=upservice.by&utm_medium=direct",
};

const ENTRY_PARAMS = "entryParams";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  params$ = new BehaviorSubject<string>("");

  constructor(
    private hostDetectionService: HostDetectionService,
    private router: Router
  ) {
    if (hostDetectionService.isBrowser) {
      const referringDomain =
        document.referrer &&
        !document.referrer.includes(hostDetectionService.hostname)
          ? `?referrer=${document.referrer}`
          : "";

      const params = window.location.search + referringDomain;
      let savedParams = window.sessionStorage.getItem(ENTRY_PARAMS);

      if (!!params && !savedParams) {
        window.sessionStorage.setItem(ENTRY_PARAMS, params);
        savedParams = params;
      }

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const referrer = `?last_page=${window.location.origin}${window.location.pathname}`;

          this.params$.next(
            this.joinParams(
              savedParams,
              PARAMS_BY_HOSTNAME[this.hostDetectionService.hostname],
              referrer
            )
          );
        }
      });
    }
  }

  joinParams(...params: string[]) {
    const parse = (query) => {
      const qs = query.startsWith("?") ? query.substring(1) : query;

      return qs
        .replace("?", "")
        .split("&")
        .reduce((acc, curr) => {
          const [key, value] = curr.split("=");

          return {
            ...acc,
            [key]: value,
          };
        }, {});
    };

    const parsed = params
      .filter(Boolean)
      .reduce((acc, curr) => ({ ...acc, ...parse(curr || "") }), {});

    const queryString: string = Object.entries(parsed)
      .map((param) => param.join("="))
      .join("&");

    return queryString.length ? "?" + queryString : "";
  }
}
