import { Routes, RouterModule } from "@angular/router";

import { LayoutComponent } from "./layout.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
  },
];

export const LayoutRoutingModule = RouterModule.forChild(routes);
