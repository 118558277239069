import { Injectable } from "@angular/core";

import { dynamicEnvironment } from "../../environments/dynamic-environment";

@Injectable({
  providedIn: "root",
})
export class AppUrlService {
  createUrl(path) {
    return `https://${dynamicEnvironment.environment.appUrl}${path}`;
  }
}
