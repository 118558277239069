import { Component, OnInit, Input } from "@angular/core";

enum Colors {
  red = "red",
  brand = "brand",
  white = "white",
}

enum Sizes {
  large = "large",
}

@Component({
  selector: "app-arrow-btn",
  templateUrl: "./arrow-btn.component.html",
  styleUrls: ["./arrow-btn.component.scss"],
})
export class ArrowBtnComponent implements OnInit {
  @Input() routerLink?: string;
  @Input() href?: string;
  @Input() text: string;
  @Input() target?: string;
  @Input() color?: Colors = Colors.brand;
  @Input() size?: Sizes | null = null;

  constructor() {}

  ngOnInit(): void {}
}
