<a
  *ngIf="routerLink"
  [routerLink]="routerLink"
  class="btn-with-arrow {{ color }} {{ size }}"
>
  {{ text }}

  <span class="arrow-wrapper">
    <span class="arrow">
      <svg
        width="30"
        height="12"
        viewBox="0 0 30 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5303 6.53033C25.8232 6.23743 25.8232 5.76256 25.5303 5.46967L20.7574 0.696697C20.4645 0.403804 19.9896 0.403804 19.6967 0.696697C19.4038 0.989591 19.4038 1.46446 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM6.55671e-08 6.75L25 6.75L25 5.25L-6.55671e-08 5.25L6.55671e-08 6.75Z"
          fill="#F9807D"
        />
      </svg>
    </span>
  </span>
</a>

<a
  *ngIf="href"
  [href]="href"
  [target]="target"
  class="btn-with-arrow {{ color }} {{ size }}"
>
  {{ text }}

  <span class="arrow-wrapper">
    <span class="arrow">
      <svg
        width="30"
        height="12"
        viewBox="0 0 30 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5303 6.53033C25.8232 6.23743 25.8232 5.76256 25.5303 5.46967L20.7574 0.696697C20.4645 0.403804 19.9896 0.403804 19.6967 0.696697C19.4038 0.989591 19.4038 1.46446 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM6.55671e-08 6.75L25 6.75L25 5.25L-6.55671e-08 5.25L6.55671e-08 6.75Z"
          fill="#F9807D"
        />
      </svg>
    </span>
  </span>
</a>
