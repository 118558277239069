<a [href]="profileUrl" class="avatar">
  <img
    loading="lazy"
    *ngIf="user?.avatarFile?.url"
    [src]="user?.avatarFile?.url"
    [alt]="name"
    width="38"
    height="38"
  />
</a>

<div class="user-data">
  <a class="user-name" [href]="profileUrl">{{ name }}</a>
  <a class="profile-link" [href]="profileUrl">Перейти в кабинет</a>
</div>
