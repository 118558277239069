import { Injectable, Injector } from "@angular/core";
import { from } from "rxjs";
import { map } from "rxjs/operators";

import { HostDetectionService } from "./host-detection.service";

declare var window: any;
declare var global: any;

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  constructor(private host: HostDetectionService, private injector: Injector) {}

  public init() {
    if (this.host.isBrowser) {
      return from(
        fetch("/assets/app-config.json").then((response) => response.json())
      )
        .pipe(
          map((config) => {
            window.config = config;
            return;
          })
        )
        .toPromise();
    }

    global.config = this.injector.get("app-config");

    return new Promise<void>((resolve) => resolve());
  }
}
