import { Component, OnInit, Input } from "@angular/core";
import { User } from "app/services/auth.service";

@Component({
  selector: "app-user-profile-menu",
  templateUrl: "./user-profile-menu.component.html",
  styleUrls: ["./user-profile-menu.component.scss"],
})
export class UserProfileMenuComponent implements OnInit {
  @Input() public user: User = null;

  public profileUrl: string = "https://app.upservice.io/auth/";

  constructor() {}

  ngOnInit(): void {}

  get name() {
    return [this.user.firstName, this.user.lastName].filter(Boolean).join(" ");
  }
}
