import { environment } from "./environment";

declare var window: any;
declare var global: any;

class DynamicEnvironment {
  public get environment() {
    const config =
      typeof global !== "undefined" ? global.config : window.config;

    return {
      ...environment,
      ...config,
    };
  }
}

export const dynamicEnvironment = new DynamicEnvironment();
