import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";

import {
  fadeAnimation,
  preventInitialAnimation,
} from "../../animations/fade.animation";
import { AnalyticsService } from "../../services/analytics.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
  animations: [preventInitialAnimation, fadeAnimation],
})
export class LayoutComponent implements OnInit {
  isBrowser: boolean;
  analyticsQueryParams = "";
  currentRoute: string = "/";

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) platformId,
    private analyticsService: AnalyticsService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : "";
  }

  ngOnInit() {
    this.currentRoute = this.router.url;

    this.router.events.subscribe((evt) => {
      if (this.isBrowser) {
        if (evt instanceof NavigationEnd) {
          window.scrollTo(0, 0);
          this.currentRoute = evt.url;
        }
      }
    });

    this.analyticsService.params$.subscribe(
      (params) => (this.analyticsQueryParams = params)
    );
  }
}
