import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FinancialHolidaysComponent } from "./financial-holidays.component";
import { ArrowBtnModule } from "@components/arrow-btn/arrow-btn.module";

@NgModule({
  declarations: [FinancialHolidaysComponent],
  imports: [CommonModule, ArrowBtnModule],
  exports: [FinancialHolidaysComponent],
})
export class FinancialHolidaysModule {}
