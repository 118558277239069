<app-header [analyticsQueryParams]="analyticsQueryParams"></app-header>

<div class="layout-inner-wrapper">
  <div class="content-wrapper" @preventInitialAnimation>
    <main
      [class.overflow]="
        currentRoute === '/upravlenie-kommercheskoj-nedvizhimostyu'
      "
      class="content"
      [@fadeAnimation]="getRouterOutletState(o)"
    >
      <router-outlet #o="outlet"></router-outlet>
    </main>
  </div>

  <app-footer></app-footer>
</div>

<app-financial-holidays [analyticsQueryParams]="analyticsQueryParams">
</app-financial-holidays>

<app-warning-cookies> </app-warning-cookies>
