const PAGES_COMMON = {
  home: "3127",
  contact: "163",
  howItWorks: "141",
  knowledgeBase: "246",
  whiteLabel: "196",
  pricing: "547",
  chat: "1023",
  repeatCustomer: "1404",
  itOutsourcing: "1753",
  serviceDesk: "1882",
  equipment: "2165",
  visiting: "2309",
  support: "2662",
  agreement: "2846",
  sellingTaskManager: "3265",
  commercialProperty: "3435",
  crmB2B: "3790",
  helpDesk: "4111",
  cleaningServices: "4656",
  horecaMaintanance: "5048",
  rentalOfEquipment: "5252",
};

const PAGES_COM = {
  ...PAGES_COMMON,
  cloud: "606",
  edo: "714",
  erp: "861",
  taskManager: "1138",
  homeB: "3130",
};

const PAGES_BY = {
  ...PAGES_COMMON,
  pricing: "5666",
  contact: "540",
  partners: "1272",
};

export enum DomainsEnum {
  UPSERVICE_BY = "upservice.by",
  UPSERVICE_COM = "upservice.com",
}

export type PageType =
  | keyof typeof PAGES_COMMON
  | keyof typeof PAGES_COM
  | keyof typeof PAGES_BY;

export const PAGES_BY_HOSTNAME = {
  [DomainsEnum.UPSERVICE_COM]: PAGES_COM,
  [DomainsEnum.UPSERVICE_BY]: PAGES_BY,
  default: PAGES_COM,
};

// Tabs on commercial-property page
export enum TabKeys {
  shopping = "shopping",
  business = "business",
  apart = "apart",
  complex = "complex",
}

export enum BlockIds {
  auto = "auto-id",
  direct = "direct-id",
  create = "create-id",
  tech = "tech-id",
  feedback = "feedback-id",
  control = "control-id",
  doc = "doc-id",
}
