import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { LayoutRoutingModule } from "./layout-routing.module";
import { WarningCookiesModule } from "../warning-cookies/warning-cookies.module";
import { FinancialHolidaysModule } from "../banner/financial-holidays/financial-holidays.module";
import { UserProfileModule } from "app/components/user-profile/user-profile.module";

import { LayoutComponent } from "./layout.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserModule,
    LayoutRoutingModule,
    WarningCookiesModule,
    FinancialHolidaysModule,
    UserProfileModule,
  ],
  providers: [],
  bootstrap: [LayoutComponent],
  exports: [LayoutComponent],
})
export class LayoutModule {}
