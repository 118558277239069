<div class="warning-cookies" *ngIf="isWarning">
  <div class="container">
    <div class="wrap-warning-cookies">
      <p class="text">
        Продолжая использовать наш сайт, вы даёте согласие на использование
        файлов cookies.
        <a class="link-more" href="/privacy-policy" target="_blank"
          >Узнать больше</a
        >
      </p>

      <button
        class="btn-transparent btn-close"
        type="button"
        (click)="handleWarning()"
      >
        Понятно
      </button>
    </div>
  </div>
</div>
