import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LayoutComponent } from "../app/components/layout/layout.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "b",
        loadChildren: () =>
          import("./pages/home-b/home-b.module").then((m) => m.HomeBModule),
      },
      {
        path: "how-it-works",
        loadChildren: () =>
          import("./pages/how-it-works/how-it-works.module").then(
            (m) => m.HowItWorksModule
          ),
      },
      {
        path: "contact",
        loadChildren: () =>
          import("./pages/contact/contact.module").then((m) => m.ContactModule),
      },
      {
        path: "contact",
        loadChildren: () =>
          import("./pages/contact/contact.module").then((m) => m.ContactModule),
      },
      {
        path: "white-label",
        loadChildren: () =>
          import("./pages/partners/partners.module").then(
            (m) => m.PartnersModule
          ),
      },
      {
        path: "blog",
        loadChildren: () =>
          import("./pages/blog/blog.module").then((m) => m.BlogModule),
      },
      // {
      //   path: "knowledge-base",
      //   loadChildren: () =>
      //     import("./pages/knowledge-base/knowledge-base.module").then(
      //       (m) => m.KnowledgeBaseModule
      //     ),
      // },
      {
        path: "pricing",
        loadChildren: () =>
          import("./pages/pricing/pricing.module").then((m) => m.PricingModule),
      },
      {
        path: "cloud",
        loadChildren: () =>
          import("./pages/cloud/cloud.module").then((m) => m.CloudModule),
      },
      {
        path: "edo",
        loadChildren: () =>
          import("./pages/edo/edo.module").then((m) => m.EdoModule),
      },
      {
        path: "erp",
        loadChildren: () =>
          import("./pages/erp/erp.module").then((m) => m.ErpModule),
      },
      {
        path: "chat",
        loadChildren: () =>
          import("./pages/chat/chat.module").then((m) => m.ChatModule),
      },
      {
        path: "task-manager",
        loadChildren: () =>
          import("./pages/task-manager/task-manager.module").then(
            (m) => m.TaskManagerModule
          ),
      },
      {
        path: "partners",
        loadChildren: () =>
          import("./pages/partner-programm/partner-programm.module").then(
            (m) => m.PartnerProgrammModule
          ),
      },
      {
        path: "repeat-customer",
        loadChildren: () =>
          import("./pages/repeat-customer/repeat-customer.module").then(
            (m) => m.RepeatCustomerModule
          ),
      },
      {
        path: "it-autsorsing",
        loadChildren: () =>
          import("./pages/it-outsourcing/it-outsourcing.module").then(
            (m) => m.ItOutsourcingModule
          ),
      },
      {
        path: "service-desk",
        loadChildren: () =>
          import("./pages/service-desk/service-desk.module").then(
            (m) => m.ServiceDeskModule
          ),
      },
      {
        path: "not-found",
        loadChildren: () =>
          import("./pages/not-found/not-found.module").then(
            (m) => m.NotFoundModule
          ),
      },
      {
        path: "obsluzhivanie-oborudovaniya-tekhniki",
        loadChildren: () =>
          import("./pages/equipment/equipment.module").then(
            (m) => m.EquipmentModule
          ),
      },
      {
        path: "vyezdnoe-obsluzhivanie",
        loadChildren: () =>
          import("./pages/visiting/visiting.module").then(
            (m) => m.VisitingModule
          ),
      },
      {
        path: "tekhnicheskaya-podderzhka",
        loadChildren: () =>
          import("./pages/support/support.module").then((m) => m.SupportModule),
      },
      {
        path: "soglasovanie-dokumentov",
        loadChildren: () =>
          import("./pages/agreement/agreement.module").then(
            (m) => m.AgreementModule
          ),
      },
      {
        path: "task-menedzher",
        loadChildren: () =>
          import(
            "./pages/selling-task-manager/selling-task-manager.module"
          ).then((m) => m.SellingTaskManagerModule),
      },
      {
        path: "upravlenie-kommercheskoj-nedvizhimostyu",
        loadChildren: () =>
          import("./pages/commercial-property/commercial-property.module").then(
            (m) => m.CommercialPropertyModule
          ),
      },
      {
        path: "crm-dlya-b2b",
        loadChildren: () =>
          import("./pages/crm-b2b/crm-b2b.module").then((m) => m.CrmB2bModule),
      },
      {
        path: "help-desk",
        loadChildren: () =>
          import("./pages/help-desk/help-desk.module").then(
            (m) => m.HelpDeskModule
          ),
      },
      {
        path: "kliningovye-uslugi",
        loadChildren: () =>
          import("./pages/cleaning-services/cleaning-services.module").then(
            (m) => m.CleaningServicesModule
          ),
      },
      {
        path: "obslujivanie-restoranov-i-gostinic",
        loadChildren: () =>
          import("./pages/horeca-maintanance/horeca-maintanance.module").then(
            (m) => m.HorecaMaintananceModule
          ),
      },
      {
        path: "arenda-oborudovaniya-tekhniki-nedvizhimosti",
        loadChildren: () =>
          import("./pages/rental-of-equipment/rental-of-equipment.module").then(
            (m) => m.RentalOfEquipmentModule
          ),
      },
      {
        path: "privacy-policy",
        loadChildren: () =>
          import("./pages/privacy-policy/privacy-policy.module").then(
            (m) => m.PrivacyPolicyModule
          ),
      },
      {
        path: "privacy-policy-eng",
        loadChildren: () =>
          import("./pages/privacy-policy/privacy-policy.module").then(
            (m) => m.PrivacyPolicyModule
          ),
      },
      {
        path: "**",
        redirectTo: "/not-found",
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
