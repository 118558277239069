import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() onClose = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  handleClose(event) {
    event.stopPropagation();

    this.onClose.emit(false);
  }
}
