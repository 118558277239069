import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { AnalyticsService } from "app/services/analytics.service";
import { AppUrlService } from "app/services/app-url.service";
import { BannerShowService } from "app/services/banner-show.service";
import { CookieMessageService } from "app/services/cookie-message.service";

@Component({
  selector: "app-financial-holidays",
  templateUrl: "./financial-holidays.component.html",
  styleUrls: ["./financial-holidays.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialHolidaysComponent implements OnInit {
  public isBannerShown: boolean = false;
  public withCookieSpacer: boolean = false;
  public date: Date = new Date(2023, 4, 31);

  @Input() analyticsQueryParams: string = "";

  constructor(
    private bannerShowService: BannerShowService,
    private cookieMessageService: CookieMessageService,
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    public appUrlService: AppUrlService
  ) {}

  ngOnInit(): void {
    this.bannerShowService.$isOpen.subscribe(
      (isOpen: boolean) => (this.isBannerShown = isOpen)
    );

    this.cookieMessageService.$isOpen.subscribe((isOpen: boolean) => {
      this.withCookieSpacer = isOpen;
      this.cdr.detectChanges();
    });

    this.analyticsService.params$.subscribe(
      (params) => (this.analyticsQueryParams = params)
    );

    const nowDate = new Date();
    this.date = this.getDate(nowDate);
    this.cdr.detectChanges();
  }

  bannerCloseHandler() {
    this.isBannerShown = false;
    this.bannerShowService.close();
  }

  getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  getDate(nowDate: Date): Date {
    const daysInCurrentMonth = this.getDaysInMonth(
      nowDate.getFullYear(),
      nowDate.getMonth() + 1
    );
    const daysInNextMonth = this.getDaysInMonth(
      nowDate.getFullYear(),
      nowDate.getMonth() + 2
    );

    // const middleOfCurrentMonth = Math.round(daysInCurrentMonth / 2);

    // if (nowDate.getDate() >= middleOfCurrentMonth) {
    //   return new Date(
    //     nowDate.getFullYear(),
    //     nowDate.getMonth() + 1,
    //     daysInNextMonth
    //   );
    // }

    return new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      daysInCurrentMonth
    );
  }
}
