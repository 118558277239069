import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class CookieMessageService {
  $isOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(@Inject(PLATFORM_ID) platformId) {
    if (isPlatformBrowser(platformId)) {
      const isOpen = !Boolean(
        window.localStorage.getItem("warnedAboutCookies")
      );

      this.$isOpen.next(isOpen);
    }
  }

  close() {
    this.$isOpen.next(false);
    this.$isOpen.complete();
    window.localStorage.setItem("warnedAboutCookies", "true");
  }
}
