import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class BannerShowService {
  $isOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(@Inject(PLATFORM_ID) platformId) {
    if (isPlatformBrowser(platformId)) {
      const nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);
      const nowDateTimestamp = nowDate.getTime();

      const lastwDate = new Date(2023, 10, 1).getTime();

      const isOpen =
        !Boolean(window.localStorage.getItem("warnedAboutBanner")) &&
        nowDateTimestamp < lastwDate;

      this.$isOpen.next(isOpen);
    }
  }

  close() {
    this.$isOpen.next(false);
    this.$isOpen.complete();
    window.localStorage.setItem("warnedAboutBanner", "true");
  }
}
