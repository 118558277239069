import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { BannerShowService } from "./services/banner-show.service";
import { HostDetectionService } from "./services/host-detection.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private host: HostDetectionService,
    private meta: Meta,
    private bannerShowService: BannerShowService
  ) {}

  public withBannerSpacer: boolean = false;

  ngOnInit() {
    this.bannerShowService.$isOpen.subscribe(
      (isOpen: boolean) => (this.withBannerSpacer = isOpen)
    );

    // TODO: move to constants
    const PROD_DOMAINS = ["upservice.com", "upservice.by"];

    if (!PROD_DOMAINS.includes(this.host.hostname)) {
      this.meta.addTags(
        [{ name: "robots", content: "noindex, nofollow" }],
        true
      );
    }
  }
}
