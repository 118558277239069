<div class="sidebar" [class.visible]="visible">
  <div class="mask" (click)="handleClose($event)">
    <div
      class="menu"
      [class.visible]="visible"
      (click)="$event.stopPropagation()"
    >
      <button class="close" (click)="handleClose($event)">
        <img
          src="assets/close.svg"
          alt="close"
          width="32"
          height="32"
          loading="lazy"
        />
      </button>
      <ng-content></ng-content>
    </div>
  </div>
</div>
